import React from 'react';

import NewslettersPage from 'components/NewslettersPage';

class NewslettersPageWrapper extends React.Component {
  render() {
    return <NewslettersPage />;
  }
}

export default NewslettersPageWrapper;
