import React from 'react';
import PropTypes from 'prop-types';
import { Nav, NavDropdown } from 'react-bootstrap';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import FaNewspaper from 'react-icons/lib/fa/newspaper-o';
import { compose } from 'react-apollo';
import { withCookies } from 'react-cookie';

// eslint-disable-next-line css-modules/no-unused-class
import s from './TopNav.scss';
import {
  DEMO_RECOMMENDATION_TYPE_KEY,
  RECOMMENDATION_TYPE_KEY,
  DEFAULT_RECOMMENDATION_TYPE_KEY,
  RECOMMENDATION_TYPE_COOKIE,
} from '../../constants';

const MenuItem = NavDropdown.Item;

const recommendationTypeKeys = [
  RECOMMENDATION_TYPE_KEY,
  DEMO_RECOMMENDATION_TYPE_KEY,
];

class RecommendationTypeSelect extends React.Component {
  static propTypes = {
    cookies: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  constructor(props) {
    super(props);

    this.handleDropdownSelect = this.handleDropdownSelect.bind(this);
  }

  handleDropdownSelect(eventKey) {
    const { cookies } = this.props;
    cookies.set(RECOMMENDATION_TYPE_COOKIE, eventKey, { path: '/' });
  }

  render() {
    const { cookies } = this.props;

    const recommendationTypeKey =
      cookies.get(RECOMMENDATION_TYPE_COOKIE) ||
      DEFAULT_RECOMMENDATION_TYPE_KEY;

    const recommendationTypeMenuItems = recommendationTypeKeys.map(key => (
      <MenuItem key={key} eventKey={key}>
        {key}
      </MenuItem>
    ));

    return (
      <Nav onSelect={this.handleDropdownSelect}>
        <NavDropdown
          className={s.navDropdown}
          title={
            <span>
              <FaNewspaper /> {recommendationTypeKey}
            </span>
          }
          alignRight
        >
          {recommendationTypeMenuItems}
        </NavDropdown>
      </Nav>
    );
  }
}

export default compose(withCookies, withStyles(s))(RecommendationTypeSelect);
