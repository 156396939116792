import React from 'react';

import NewsletterUserNewslettersPage from 'components/NewsletterUserNewslettersPage';

class NewsletterUserNewslettersPageWrapper extends React.Component {
  render() {
    return <NewsletterUserNewslettersPage />;
  }
}

export default NewsletterUserNewslettersPageWrapper;
