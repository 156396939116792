export const SET_RUNTIME_VARIABLE = 'SET_RUNTIME_VARIABLE';
export const SET_LOCALE_START = 'SET_LOCALE_START';
export const SET_LOCALE_SUCCESS = 'SET_LOCALE_SUCCESS';
export const SET_LOCALE_ERROR = 'SET_LOCALE_ERROR';

export const SET_LICENSE_STATE = 'SET_LICENSE_STATE';

export const USER_TOKEN_ID = 'id_token';

export const MATRIX_TYPE_POSITIVE = 'POSITIVE';
export const MATRIX_TYPE_NEGATIVE = 'NEGATIVE';

// item states
export const ITEM_RESET = 0;
export const ITEM_STATE_CLICKED = 1;
export const ITEM_STATE_NOT_CLICKED = 2;

// interaction types
export const INTERACTION_TYPE_CLICKED = 'clicked';
export const INTERACTION_TYPE_SKIPPED = 'skipped';

// danube rules
export const INVERSE_PERCENTAGE_SINE = 'INVERSE_PERCENTAGE_SINE';
export const PERCENTAGE = 'PERCENTAGE';
export const INVERSE_PERCENTAGE = 'INVERSE_PERCENTAGE';

// recommendation types
export const DEMO_RECOMMENDATION_TYPE_KEY = 'demo-articles';
export const RECOMMENDATION_TYPE_KEY = 'articles';
export const DEFAULT_RECOMMENDATION_TYPE_KEY = RECOMMENDATION_TYPE_KEY;

// testing constants
export const MATRIX_FILL_BATCH_SIZE = 20000;
export const VALIDATION_PERCENTAGE = 0.5;
export const USER_SEPARATION_SEED = 1;

// other constants
export const USER_HISTORY_LIMIT_FOR_RECOMMENDATION = 100;

// Pagination
export const NUMBER_OF_LIST_ITEMS = 10;

// different metrics
export const METRIC_KEYS = [
  'absolutePositionMetric',
  'relativePositionMetric',
  'oldMetric',
  'positionDivisorMetric',
];
export const DEMO_METRIC_KEYS = METRIC_KEYS;
export const LIVE_METRIC_KEYS = ['absolutePositionMetric'];
export const METRIC_LABELS = {
  absolutePositionMetric: 'Abs. pos. metric',
  relativePositionMetric: 'Rel. pos. metric',
  oldMetric: 'Skip-based metric',
  positionDivisorMetric: 'Pos. div. metric',
};

export const RECOMMENDATION_TYPE_COOKIE = 'recommendation_type';
