import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { graphql, compose } from 'react-apollo';
import { withRouter } from 'react-router';

import { meQuery } from '../../data/queries/userQueries';
import Loading from '../Loading';

const AuthenticationWrapper = WrappedComponent => {
  class WithAuthentication extends React.Component {
    static propTypes = {
      meData: PropTypes.shape({
        loading: PropTypes.bool.isRequired,
        me: PropTypes.shape({
          id: PropTypes.string.isRequired,
          username: PropTypes.string.isRequired,
        }),
      }).isRequired,
    };

    render() {
      const { meData } = this.props;

      if (meData.loading) return <Loading />;

      if (!meData.me) return <Redirect to="/login" />;

      return <WrappedComponent {...this.props} />;
    }
  }

  return compose(
    graphql(meQuery, {
      name: 'meData',
    }),
    withRouter,
  )(WithAuthentication);
};

export default AuthenticationWrapper;
