import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { compose } from 'react-apollo';
import { withCookies } from 'react-cookie';

import s from './LandingPage.scss';
import withAuthentication from '../Auth/AuthenticationWrapper';
import NewslettersChart from '../NewslettersChart';
import {
  RECOMMENDATION_TYPE_COOKIE,
  DEFAULT_RECOMMENDATION_TYPE_KEY,
} from '../../constants';

class LandingPage extends React.Component {
  static propTypes = {
    cookies: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  constructor(props) {
    super(props);

    this.state = {
      recommendationTypeKey:
        props.cookies.get(RECOMMENDATION_TYPE_COOKIE) ||
        DEFAULT_RECOMMENDATION_TYPE_KEY,
    };
  }

  componentDidUpdate() {
    const recommendationTypeKey =
      this.props.cookies.get(RECOMMENDATION_TYPE_COOKIE) ||
      DEFAULT_RECOMMENDATION_TYPE_KEY;
    if (this.state.recommendationTypeKey !== recommendationTypeKey) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ recommendationTypeKey });
    }
  }

  render() {
    const { recommendationTypeKey } = this.state;

    return (
      <div className={s.landingPageContainer}>
        <h4>
          <NewslettersChart recommendationTypeKey={recommendationTypeKey} />
        </h4>
      </div>
    );
  }
}

export default compose(
  withAuthentication,
  withCookies,
  withStyles(s),
)(LandingPage);
