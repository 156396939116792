exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Rcr6F{width:100%;max-width:1500px;margin:auto;padding:0 15px}.Rcr6F ._39LyW{padding-top:30px;color:#fff;font-size:20px}.Rcr6F ._1tOWP{height:auto;max-width:800px;width:100%;margin:auto;padding-top:30px}.Rcr6F ._3GmvI{display:inline-block;margin-right:25px}.Rcr6F ._1Dy3m{width:100%;text-align:left;overflow:hidden}.Rcr6F ._1Dy3m ._26UAg{-o-text-overflow:ellipsis;text-overflow:ellipsis;overflow:hidden;white-space:nowrap}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"newsletterUserNewslettersPage": "Rcr6F",
	"userInfoContainer": "_39LyW",
	"lineChartContainer": "_1tOWP",
	"infoSpan": "_3GmvI",
	"newsletterHeader": "_1Dy3m",
	"subject": "_26UAg"
};