/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { graphql, compose } from 'react-apollo';
import FaCaretDown from 'react-icons/lib/fa/caret-down';
import FaCaretUp from 'react-icons/lib/fa/caret-up';
import FaMinus from 'react-icons/lib/fa/minus';
import { withCookies } from 'react-cookie';

import s from './NewsletterUserNewsletterDataPage.scss';
import withAuthentication from '../Auth/AuthenticationWrapper';
import { getNewsletterUserNewsletterDataQuery } from '../../data/queries/newsletterQueries';
import Loading from '../Loading';
import { roundNumber } from '../../util/math';
import {
  INTERACTION_TYPE_CLICKED,
  RECOMMENDATION_TYPE_COOKIE,
  DEMO_RECOMMENDATION_TYPE_KEY,
  DEFAULT_RECOMMENDATION_TYPE_KEY,
  DEMO_METRIC_KEYS,
  LIVE_METRIC_KEYS,
  METRIC_LABELS,
} from '../../constants';

class NewsletterUserNewsletterDataPage extends React.Component {
  static propTypes = {
    cookies: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    match: PropTypes.shape({
      params: PropTypes.shape({
        externalUserId: PropTypes.string.isRequired,
        newsletterId: PropTypes.string.isRequired,
      }),
    }).isRequired,
    newsletterUserNewsletterData: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      getNewsletterUserNewsletterData: PropTypes.shape({
        orderedArticles: PropTypes.array, // eslint-disable-line react/forbid-prop-types
        userInteractions: PropTypes.array, // eslint-disable-line react/forbid-prop-types
        originalOrder: PropTypes.array, // eslint-disable-line react/forbid-prop-types
        articleRecommendationItems: PropTypes.array, // eslint-disable-line react/forbid-prop-types
        averageMetrics: PropTypes.number,
      }),
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.renderArticle = this.renderArticle.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  renderArticle(articleData) {
    const { cookies } = this.props;

    const recommendationTypeKey =
      cookies.get(RECOMMENDATION_TYPE_COOKIE) ||
      DEFAULT_RECOMMENDATION_TYPE_KEY;

    return (
      <div
        className={`${s.article} ${
          articleData.userInteraction &&
          articleData.userInteraction.type === INTERACTION_TYPE_CLICKED
            ? s.clicked
            : ''
        }`}
      >
        <span className={s.articleLabel}>Article:</span>
        <a
          className={`${s.articleTitle} ${s.externalLink}`}
          href={articleData.articleRecommendationItem.externalApiLink}
          target="_blank"
          rel="noreferrer"
        >
          {articleData.articleRecommendationItem.title ||
            articleData.articleRecommendationItem.externalApiId}
        </a>
        {articleData.userInteraction && [
          <span className={s.clickedSpan}>
            {articleData.userInteraction.type}
          </span>,
          <span className={s.clickedSpan}>Pos: </span>,
          <span className={s.clickedPosSpan}>
            {articleData.userInteraction.position + 1}
          </span>,
          articleData.originalOrder != null && (
            <span>
              {articleData.originalOrder == null ||
              articleData.userInteraction.position -
                articleData.originalOrder ===
                0 ? (
                <div className={s.neutralCounter}>
                  <FaMinus />
                </div>
              ) : articleData.userInteraction.position <
                articleData.originalOrder ? (
                <div className={s.positiveCounter}>
                  <FaCaretUp />
                  <span>
                    {articleData.originalOrder -
                      articleData.userInteraction.position}
                  </span>
                </div>
              ) : (
                <div className={s.negativeCounter}>
                  <FaCaretDown />
                  <span>
                    {articleData.userInteraction.position -
                      articleData.originalOrder}
                  </span>
                </div>
              )}
            </span>
          ),
          <div className={s.metrics}>
            {articleData.userInteraction.metric != null &&
              (recommendationTypeKey === DEMO_RECOMMENDATION_TYPE_KEY
                ? DEMO_METRIC_KEYS
                : LIVE_METRIC_KEYS
              ).map(metricKey => (
                <span className={s.infoSpan}>
                  {METRIC_LABELS[metricKey] || metricKey}:{' '}
                  {roundNumber({
                    value: articleData.userInteraction.metric[metricKey],
                    numberOfCommaDigits: 4,
                  })}
                </span>
              ))}
          </div>,
        ]}
        <div>
          <span className={s.articleLabel}>External id: </span>
          <span>{articleData.articleRecommendationItem.externalApiId}</span>
        </div>
      </div>
    );
  }

  render() {
    const { cookies, newsletterUserNewsletterData } = this.props;

    if (newsletterUserNewsletterData.loading) {
      return <Loading />;
    }

    const recommendationTypeKey =
      cookies.get(RECOMMENDATION_TYPE_COOKIE) ||
      DEFAULT_RECOMMENDATION_TYPE_KEY;

    const {
      orderedArticles,
      userInteractions,
      originalOrder,
      articleRecommendationItems,
      averageMetrics,
    } = newsletterUserNewsletterData.getNewsletterUserNewsletterData;

    const articlesData = orderedArticles.map(article => {
      const data = { ...article };
      const userInteraction = userInteractions.find(
        interaction => interaction.articleId === article.id,
      );
      const articleRecommendationItem = articleRecommendationItems.find(
        item => item.id === article.id,
      );
      const origOrder = originalOrder
        ? originalOrder.indexOf(article.externalArticleId)
        : null;
      if (origOrder != null) {
        data.originalOrder = origOrder;
      }
      if (userInteraction != null) {
        data.userInteraction = { ...userInteraction };
      }

      if (articleRecommendationItem != null) {
        data.articleRecommendationItem = { ...articleRecommendationItem };
      }

      return data;
    });

    return (
      <div className={s.newsletterUsersPage}>
        <div className={s.averageMetricContainer}>
          Avgerage metrics:{' '}
          <div>
            {averageMetrics != null &&
              (recommendationTypeKey === DEMO_RECOMMENDATION_TYPE_KEY
                ? DEMO_METRIC_KEYS
                : LIVE_METRIC_KEYS
              ).map(metricKey => (
                <span className={s.infoSpan}>
                  {METRIC_LABELS[metricKey] || metricKey}:{' '}
                  {roundNumber({
                    value: averageMetrics[metricKey],
                    numberOfCommaDigits: 4,
                  })}
                </span>
              ))}
          </div>
        </div>
        {articlesData.map(articleData => this.renderArticle(articleData))}
      </div>
    );
  }
}

export default compose(
  withAuthentication,
  withCookies,
  graphql(getNewsletterUserNewsletterDataQuery, {
    name: 'newsletterUserNewsletterData',
    options: props => ({
      variables: {
        recommendationTypeKey:
          props.cookies.get(RECOMMENDATION_TYPE_COOKIE) ||
          DEFAULT_RECOMMENDATION_TYPE_KEY,
        externalUserId: props.match.params.externalUserId,
        newsletterId: props.match.params.newsletterId,
      },
    }),
  }),
  withStyles(s),
)(NewsletterUserNewsletterDataPage);
