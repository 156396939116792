/* eslint-disable import/prefer-default-export */
// common utility and helper functions

export const sleep = milliseconds =>
  new Promise(resolve => setTimeout(resolve, milliseconds));

export const isClientSide = () =>
  !!(
    typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement
  );

export const isServerSide = () => !isClientSide();
