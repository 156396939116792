import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { graphql, compose } from 'react-apollo';
import { Line as LineChart } from 'react-chartjs-2';

import s from './NewsletterUserMetricChart.scss';
import { getGlobalUserNewsletterMetricsQuery } from '../../data/queries/newsletterQueries';
import {
  DEMO_METRIC_KEYS,
  LIVE_METRIC_KEYS,
  DEMO_RECOMMENDATION_TYPE_KEY,
  METRIC_LABELS,
} from '../../constants';

const chartColors = ['#4caf50', '#af8b4c', '#0db5ff'];

class NewsletterUserMetricChart extends React.Component {
  static propTypes = {
    recommendationTypeKey: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
    externalUserId: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
    compact: PropTypes.bool,
    globalUserNewsletterMetricsData: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      getGlobalUserNewsletterMetrics: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
  };

  static defaultProps = {
    compact: false,
  };

  constructor(props) {
    super(props);

    this.renderLineChart = this.renderLineChart.bind(this);
  }

  renderLineChart() {
    const {
      recommendationTypeKey,
      compact,
      globalUserNewsletterMetricsData,
    } = this.props;
    const {
      getGlobalUserNewsletterMetrics: metrics,
    } = globalUserNewsletterMetricsData;

    const filteredMetrics = metrics.filter(
      metric => metric.absolutePositionMetric != null,
    );

    const data = {
      labels: filteredMetrics.map((_, index) =>
        compact ? '' : `${index + 1} NL`,
      ),
      datasets: (recommendationTypeKey === DEMO_RECOMMENDATION_TYPE_KEY
        ? DEMO_METRIC_KEYS
        : LIVE_METRIC_KEYS
      ).map((metricKey, index) => ({
        label: METRIC_LABELS[metricKey] || metricKey,
        fill: false,
        data: filteredMetrics.map(metric =>
          metric != null ? metric[metricKey] : null,
        ),
        borderColor: chartColors[index],
        backgroundColor: chartColors[index],
      })),
    };

    let options;

    if (compact) {
      options = {
        responsive: true,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              ticks: {
                color: '#ffffff',
                fontColor: '#ffffff',
              },
              gridLines: {
                color: '#00000000',
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                color: '#ffffff',
                fontColor: '#ffffff',
                beginAtZero: false,
                max: 1,
                min: -1,
                stepSize: 0.5,
              },
              gridLines: {
                color: '#888888',
              },
            },
          ],
        },
      };
    } else {
      options = {
        responsive: true,
        legend: {
          labels: {
            fontColor: '#ffffff',
          },
        },
        scales: {
          xAxes: [
            {
              ticks: {
                color: '#ffffff',
                fontColor: '#ffffff',
              },
              gridLines: {
                color: '#888888',
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                color: '#ffffff',
                fontColor: '#ffffff',
                max: 1,
                min: -1,
              },
              gridLines: {
                color: '#888888',
              },
            },
          ],
        },
      };
    }

    return (
      <div className={s.lineChartContainer}>
        <LineChart data={data} options={options} />
      </div>
    );
  }

  render() {
    const { globalUserNewsletterMetricsData } = this.props;

    if (globalUserNewsletterMetricsData.loading) {
      return <div className={s.lineChartContainer}>Loading...</div>;
    }

    return this.renderLineChart();
  }
}

export default compose(
  graphql(getGlobalUserNewsletterMetricsQuery, {
    name: 'globalUserNewsletterMetricsData',
    options: props => ({
      variables: {
        recommendationTypeKey: props.recommendationTypeKey,
        externalUserId: props.externalUserId,
      },
    }),
  }),
  withStyles(s),
)(NewsletterUserMetricChart);
