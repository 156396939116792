import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { compose } from 'react-apollo';
import { Link } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import moment from 'moment';

import s from './NewslettersPage.scss';
import withAuthentication from '../Auth/AuthenticationWrapper';
import PaginatedList from '../PaginatedList';
import CollapsiblePanel from '../CollapsiblePanel';
import { getNewslettersQuery } from '../../data/queries/newsletterQueries';
import {
  RECOMMENDATION_TYPE_COOKIE,
  DEFAULT_RECOMMENDATION_TYPE_KEY,
} from '../../constants';
import { roundNumber } from '../../util/math';

class NewslettersPage extends React.Component {
  static propTypes = {
    cookies: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      recommendationTypeKey:
        props.cookies.get(RECOMMENDATION_TYPE_COOKIE) ||
        DEFAULT_RECOMMENDATION_TYPE_KEY,
      loading: false,
      newsletters: [],
      newsletterCount: 0,
    };

    this.fetchNewsletters = this.fetchNewsletters.bind(this);
    this.renderNewsletter = this.renderNewsletter.bind(this);
  }

  componentDidUpdate() {
    const recommendationTypeKey =
      this.props.cookies.get(RECOMMENDATION_TYPE_COOKIE) ||
      DEFAULT_RECOMMENDATION_TYPE_KEY;
    if (this.state.recommendationTypeKey !== recommendationTypeKey) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ recommendationTypeKey });
    }
  }

  async fetchNewsletters({ offset, limit }) {
    const { recommendationTypeKey } = this.state;

    this.setState({
      loading: true,
    });

    const result = await this.context.client.query({
      query: getNewslettersQuery,
      variables: {
        recommendationTypeKey,
        offset,
        limit,
      },
    });

    if (result.data && result.data.getNewsletters) {
      this.setState({
        loading: false,
        newsletters: result.data.getNewsletters.newsletters,
        newsletterCount: result.data.getNewsletters.count,
      });
    } else {
      this.setState({
        loading: false,
        newsletters: [],
        newsletterCount: 0,
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  renderNewsletter(newsletter) {
    const dateFormat = 'DD.MM.YYYY';
    const createdAtDate = moment(parseInt(newsletter.createdAt, 10)).format(
      dateFormat,
    );
    const sentAtDate = moment(parseInt(newsletter.sentAt, 10)).format(
      dateFormat,
    );
    return (
      <CollapsiblePanel
        key={newsletter.externalNewsletterId}
        headerContent={
          <div className={s.newsletterHeader}>
            <div className={s.subject}>
              Newsletter:{' '}
              <Link to={`/newsletters/${newsletter.newsletterId}`}>
                {newsletter.newsletterSubject ||
                  newsletter.externalNewsletterId}
              </Link>
            </div>
            <div>
              <span className={s.infoSpan}>Created at: {createdAtDate}</span>
              <span className={s.infoSpan}>Sent: {newsletter.sentCount}</span>
              <span className={s.infoSpan}>Opened: {newsletter.openCount}</span>
              <span className={s.infoSpan}>
                Open rate:{' '}
                {roundNumber({
                  value: (newsletter.openCount / newsletter.sentCount) * 100,
                  numberOfCommaDigits: 2,
                })}
                %
              </span>
            </div>
            <div>
              <span className={s.infoSpan}>
                Clicks: {newsletter.clickCount}
              </span>
              <span className={s.infoSpan}>
                Average clicks (all):{' '}
                {roundNumber({
                  value: newsletter.clickCount / newsletter.sentCount,
                  numberOfCommaDigits: 2,
                })}
              </span>
              <span className={s.infoSpan}>
                Average clicks (opened):{' '}
                {roundNumber({
                  value: newsletter.clickCount / newsletter.openCount,
                  numberOfCommaDigits: 2,
                })}
              </span>
            </div>
          </div>
        }
        bodyContent={
          <div>
            <div>External id: {newsletter.externalNewsletterId}</div>
            <div>Sent at: {sentAtDate}</div>
          </div>
        }
      />
    );
  }

  render() {
    const {
      recommendationTypeKey,
      loading,
      newsletters,
      newsletterCount,
    } = this.state;

    return (
      <div className={s.newslettersPage}>
        <PaginatedList
          recommendationTypeKey={recommendationTypeKey}
          items={newsletters}
          itemCount={newsletterCount}
          renderItem={this.renderNewsletter}
          fetchItems={this.fetchNewsletters}
          loading={loading}
        />
      </div>
    );
  }
}

export default compose(
  withAuthentication,
  withCookies,
  withStyles(s),
)(NewslettersPage);
