import React from 'react';
import PropTypes from 'prop-types';

import { loginQuery, meQuery } from '../../data/queries/userQueries';
import { setUserTokenCookie } from './cookieAndLocalStorage';

function withLogin(WrappedComponent) {
  class LoginWrapper extends React.Component {
    static contextTypes = {
      client: PropTypes.object.isRequired,
    };

    constructor(props) {
      super(props);

      this.login = this.login.bind(this);
    }

    async login({ username, password }) {
      const response = await this.context.client.query({
        query: loginQuery,
        variables: {
          username,
          password,
        },
        fetchPolicy: 'network-only',
      });
      if (
        response &&
        response.data &&
        response.data.login &&
        response.data.login.success
      ) {
        setUserTokenCookie(response.data.login.token);

        // refetch meQuery manually
        await this.context.client.query({
          query: meQuery,
          fetchPolicy: 'network-only',
        });

        return true;
      }
      return false;
    }

    render() {
      return <WrappedComponent login={this.login} {...this.props} />;
    }
  }

  return LoginWrapper;
}

export default withLogin;
