import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { compose } from 'react-apollo';
import { Link } from 'react-router-dom';
import { withCookies } from 'react-cookie';

import s from './NewsletterUserNewslettersPage.scss';
import withAuthentication from '../Auth/AuthenticationWrapper';
import PaginatedList from '../PaginatedList';
import CollapsiblePanel from '../CollapsiblePanel';
import { getNewsletterUserNewslettersQuery } from '../../data/queries/newsletterQueries';
import NewsletterUserMetricChart from '../NewsletterUserMetricChart';
import { roundNumber } from '../../util/math';
import {
  RECOMMENDATION_TYPE_COOKIE,
  DEMO_RECOMMENDATION_TYPE_KEY,
  DEFAULT_RECOMMENDATION_TYPE_KEY,
  DEMO_METRIC_KEYS,
  LIVE_METRIC_KEYS,
  METRIC_LABELS,
} from '../../constants';

class NewsletterUserNewslettersPage extends React.Component {
  static propTypes = {
    cookies: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    match: PropTypes.shape({
      params: PropTypes.shape({
        externalUserId: PropTypes.string.isRequired,
      }),
    }).isRequired,
  };

  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      recommendationTypeKey:
        props.cookies.get(RECOMMENDATION_TYPE_COOKIE) ||
        DEFAULT_RECOMMENDATION_TYPE_KEY,
      loading: false,
      userNewsletters: [],
      userNewsletterCount: 0,
    };

    this.fetchUserNewsletters = this.fetchUserNewsletters.bind(this);
    this.renderUserNewsletter = this.renderUserNewsletter.bind(this);
  }

  componentDidUpdate() {
    const recommendationTypeKey =
      this.props.cookies.get(RECOMMENDATION_TYPE_COOKIE) ||
      DEFAULT_RECOMMENDATION_TYPE_KEY;
    if (this.state.recommendationTypeKey !== recommendationTypeKey) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ recommendationTypeKey });
    }
  }

  async fetchUserNewsletters({ offset, limit }) {
    const { recommendationTypeKey } = this.state;

    this.setState({
      loading: true,
    });

    const result = await this.context.client.query({
      query: getNewsletterUserNewslettersQuery,
      variables: {
        recommendationTypeKey,
        externalUserId: this.props.match.params.externalUserId,
        offset,
        limit,
      },
    });

    if (result.data && result.data.getNewsletterUserNewsletters) {
      this.setState({
        loading: false,
        userNewsletters:
          result.data.getNewsletterUserNewsletters.userNewsletters,
        userNewsletterCount: result.data.getNewsletterUserNewsletters.count,
      });
    } else {
      this.setState({
        loading: false,
        userNewsletters: [],
        userNewsletterCount: 0,
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  renderUserNewsletter(userNewsletter) {
    const { recommendationTypeKey } = this.state;

    return (
      <CollapsiblePanel
        key={userNewsletter.externalNewsletterId}
        headerContent={
          <div className={s.newsletterHeader}>
            <div className={s.subject}>
              Newsletter:{' '}
              <Link
                to={`/users/${this.props.match.params.externalUserId}/${userNewsletter.newsletterId}`}
              >
                {userNewsletter.newsletterSubject ||
                  userNewsletter.externalNewsletterId}
              </Link>
            </div>
            <div>
              <span className={s.infoSpan}>
                Articles: {userNewsletter.articleCount}
              </span>
              <span className={s.infoSpan}>
                Clicks: {userNewsletter.clickCount}
              </span>
              <div>
                <span className={s.infoSpan}>Average metrics:</span>
              </div>
              <div>
                {userNewsletter.averageClickMetrics != null &&
                  (recommendationTypeKey === DEMO_RECOMMENDATION_TYPE_KEY
                    ? DEMO_METRIC_KEYS
                    : LIVE_METRIC_KEYS
                  ).map(metricKey => (
                    <span className={s.infoSpan}>
                      {METRIC_LABELS[metricKey] || metricKey}:{' '}
                      {roundNumber({
                        value: userNewsletter.averageClickMetrics[metricKey],
                        numberOfCommaDigits: 4,
                      })}
                    </span>
                  ))}
              </div>
            </div>
          </div>
        }
        bodyContent={
          <div>
            <div>External id: {userNewsletter.externalNewsletterId}</div>
            <div>Received at: {userNewsletter.receivedAt}</div>
          </div>
        }
      />
    );
  }

  render() {
    const {
      recommendationTypeKey,
      loading,
      userNewsletters,
      userNewsletterCount,
    } = this.state;

    return (
      <div className={s.newsletterUserNewslettersPage}>
        <div className={s.userInfoContainer}>
          User - {this.props.match.params.externalUserId}
        </div>
        <div className={s.lineChartContainer}>
          <NewsletterUserMetricChart
            recommendationTypeKey={recommendationTypeKey}
            externalUserId={this.props.match.params.externalUserId}
          />
        </div>
        <PaginatedList
          recommendationTypeKey={recommendationTypeKey}
          items={userNewsletters}
          itemCount={userNewsletterCount}
          renderItem={this.renderUserNewsletter}
          fetchItems={this.fetchUserNewsletters}
          loading={loading}
        />
      </div>
    );
  }
}

export default compose(
  withAuthentication,
  withCookies,
  withStyles(s),
)(NewsletterUserNewslettersPage);
