exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._9weJF{width:100%;max-width:1500px;margin:auto;padding:30px 15px;position:relative}._9weJF .FkndR{padding:10px 25px;color:#8bc34a}._9weJF .VDT1d{border:1px solid #fff;border-radius:0;background:none;color:#fff}._9weJF .VDT1d,._9weJF .VDT1d._3zh3p{width:100%;margin:10px 0;padding:10px}._9weJF .VDT1d._3zh3p{border:1px solid #b4b4b4;background-color:#2e4b5f;overflow:hidden}._9weJF ._1_7fg,._9weJF ._2jtte{margin-left:15px;vertical-align:middle}._9weJF ._2jtte{width:50%;max-width:600px;display:inline-block;text-align:left;overflow-x:hidden;white-space:nowrap;-o-text-overflow:ellipsis;text-overflow:ellipsis}._9weJF ._3V4Rw{margin-left:65px;text-align:center;color:#fff}._9weJF ._1PzTd,._9weJF ._3V4Rw{font-family:monospace;display:inline-block;vertical-align:middle}._9weJF ._1PzTd{text-align:right;width:2.5%;padding:0;margin:0}._9weJF ._1dn3L{color:#4caf50;vertical-align:middle}._9weJF ._1dn3L,._9weJF ._2aS5F{font-family:monospace;text-align:right;display:inline-block;width:40px;margin-left:65px}._9weJF ._2aS5F{color:#bb3431}._9weJF .miHbW{font-family:monospace;text-align:right;display:inline-block;width:40px;color:#333;margin-left:65px}._9weJF ._3rYUl{color:#fff;display:inline-block}._9weJF ._3rYUl:hover{text-decoration:underline;color:#fff}._9weJF .YF6Rk{margin-left:15px}._9weJF ._1r55R{display:inline-block;margin-right:25px}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"newsletterUsersPage": "_9weJF",
	"averageMetricContainer": "FkndR",
	"article": "VDT1d",
	"clicked": "_3zh3p",
	"articleLabel": "_1_7fg",
	"articleTitle": "_2jtte",
	"clickedSpan": "_3V4Rw",
	"clickedPosSpan": "_1PzTd",
	"positiveCounter": "_1dn3L",
	"negativeCounter": "_2aS5F",
	"neutralCounter": "miHbW",
	"externalLink": "_3rYUl",
	"metrics": "YF6Rk",
	"infoSpan": "_1r55R"
};