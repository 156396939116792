import { defineMessages } from 'react-intl';

const messages = defineMessages({
  page: {
    id: 'demoUsers.page',
    defaultMessage: '{currentPage} of {numberOfPages}',
    description: 'label for PageLabel',
  },
});

export default messages;
