import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { graphql, compose } from 'react-apollo';
import { Line as LineChart } from 'react-chartjs-2';
import FaRefresh from 'react-icons/lib/fa/refresh';

import s from './NewslettersChart.scss';
import { getGlobalNewsletterStatistics } from '../../data/queries/newsletterQueries';

const chartColors = [
  '#4caf50',
  '#af8b4c',
  '#0db5ff',
  '#66ead0',
  '#ff5722',
  '#9c27b0',
  '#a388d3',
  '#fbfbfb',
];

class NewslettersChart extends React.Component {
  static propTypes = {
    recommendationTypeKey: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
    globalNewsletterStatisticsData: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      refetch: PropTypes.func.isRequired,
      getGlobalNewsletterStatistics: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      refetching: false,
    };

    this.renderLineChart = this.renderLineChart.bind(this);
    this.renderAvgAbsolutePositionMetricLineChart = this.renderAvgAbsolutePositionMetricLineChart.bind(
      this,
    );
    this.renderGroupClickRateLineChart = this.renderGroupClickRateLineChart.bind(
      this,
    );
    this.renderGroupOpenRateLineChart = this.renderGroupOpenRateLineChart.bind(
      this,
    );
    this.renderSentAndOpenedLineChart = this.renderSentAndOpenedLineChart.bind(this); // eslint-disable-line prettier/prettier
    this.renderOpenRateLineChart = this.renderOpenRateLineChart.bind(this);
    this.renderClickCountLineChart = this.renderClickCountLineChart.bind(this);
    this.renderAverageSentClickCountLineChart = this.renderAverageSentClickCountLineChart.bind(this); // eslint-disable-line prettier/prettier
    this.renderAverageOpenedClickCountLineChart = this.renderAverageOpenedClickCountLineChart.bind(this); // eslint-disable-line prettier/prettier
  }

  renderLineChart({
    datasetLabels,
    dataExtractors,
    datasetColors,
    xAxisLength = null,
    xAxisLabels = 'NL',
  }) {
    const { globalNewsletterStatisticsData } = this.props;
    const {
      getGlobalNewsletterStatistics: newslettersData,
    } = globalNewsletterStatisticsData;

    const data = {
      labels: newslettersData
        .slice(...(xAxisLength != null ? [0, xAxisLength] : [0]))
        .map((_, index) => `${xAxisLabels} ${index + 1}`),
      datasets: datasetLabels.map((datasetLabel, index) => ({
        label: datasetLabel,
        fill: false,
        data: newslettersData
          .slice(...(xAxisLength != null ? [0, xAxisLength] : [0]))
          .map(newsletterData => dataExtractors[index](newsletterData)),
        borderColor: datasetColors[index],
        backgroundColor: datasetColors[index],
      })),
    };

    const options = {
      responsive: true,
      legend: {
        labels: {
          fontColor: '#ffffff',
        },
      },
      scales: {
        xAxes: [
          {
            ticks: {
              color: '#ffffff',
              fontColor: '#ffffff',
            },
            gridLines: {
              color: '#888888',
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              color: '#ffffff',
              fontColor: '#ffffff',
              min: 0,
            },
            gridLines: {
              color: '#888888',
            },
          },
        ],
      },
    };
    return <LineChart data={data} options={options} />;
  }

  // eslint-disable-next-line class-methods-use-this
  renderAvgAbsolutePositionMetricLineChart(xAxisLabels) {
    const { globalNewsletterStatisticsData } = this.props;
    const {
      getGlobalNewsletterStatistics: newslettersData,
    } = globalNewsletterStatisticsData;

    let labels = [];
    let extractors = [];
    const hasResult = [];

    if (newslettersData && newslettersData.length !== 0) {
      labels = newslettersData[0].groupMetric.map(element =>
        element.userGroupName.concat('-', element.newsletterGroupName),
      );
      extractors = labels.map((element, index) => newsletterData =>
        newsletterData != null
          ? newsletterData?.groupMetric[index].avgAbsolutePositionMetric
          : null,
      );
      newslettersData.forEach((newsletter, index) => {
        const results = newsletter.groupMetric
          .map(groupResult => groupResult.avgAbsolutePositionMetric)
          .filter(element => element);
        if (results.length > 0) {
          hasResult.push(index);
        }
      });
    }

    return this.renderLineChart({
      datasetLabels: labels,
      dataExtractors: extractors,
      datasetColors: chartColors,
      xAxisLength: Math.max(...hasResult) + 1,
      xAxisLabels,
    });
  }

  renderGroupClickRateLineChart() {
    const { globalNewsletterStatisticsData } = this.props;
    const {
      getGlobalNewsletterStatistics: newslettersData,
    } = globalNewsletterStatisticsData;

    let labels = [];
    let extractors = [];

    if (newslettersData && newslettersData.length !== 0) {
      labels = newslettersData[0].groupMetric.map(element =>
        element.userGroupName.concat('-', element.newsletterGroupName),
      );
      extractors = labels.map((element, index) => newsletterData =>
        newsletterData !== null &&
        newsletterData.groupMetric[index]?.clickSum !== null
          ? newsletterData.groupMetric[index].clickSum /
            newsletterData.groupMetric[index].userGroupSize
          : null,
      );
    }

    return this.renderLineChart({
      datasetLabels: labels,
      dataExtractors: extractors,
      datasetColors: chartColors,
    });
  }

  renderGroupOpenRateLineChart() {
    const { globalNewsletterStatisticsData } = this.props;
    const {
      getGlobalNewsletterStatistics: newslettersData,
    } = globalNewsletterStatisticsData;

    let labels = [];
    let extractors = [];

    if (newslettersData && newslettersData.length !== 0) {
      labels = newslettersData[0].groupMetric.map(element =>
        element.userGroupName.concat('-', element.newsletterGroupName),
      );
      extractors = labels.map((element, index) => newsletterData =>
        newsletterData != null &&
        newsletterData.groupMetric[index]?.clickSum !== null &&
        newsletterData.groupMetric[index]?.openingSum !== null
          ? newsletterData?.groupMetric[index].clickSum /
            newsletterData?.groupMetric[index].openingSum
          : null,
      );
    }

    return this.renderLineChart({
      datasetLabels: labels,
      dataExtractors: extractors,
      datasetColors: chartColors,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  renderSentAndOpenedLineChart() {
    return this.renderLineChart({
      datasetLabels: ['Sent', 'Opened'],
      dataExtractors: [
        newsletterData =>
          newsletterData != null ? newsletterData.sentCount : null,
        newsletterData =>
          newsletterData != null ? newsletterData.openCount : null,
      ],
      datasetColors: chartColors,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  renderOpenRateLineChart() {
    return this.renderLineChart({
      datasetLabels: ['Open rate'],
      dataExtractors: [
        newsletterData =>
          newsletterData != null
            ? newsletterData.openCount / newsletterData.sentCount
            : null,
      ],
      datasetColors: chartColors,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  renderClickCountLineChart() {
    return this.renderLineChart({
      datasetLabels: ['Clicks'],
      dataExtractors: [
        newsletterData =>
          newsletterData != null ? newsletterData.clickCount : null,
      ],
      datasetColors: chartColors,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  renderAverageSentClickCountLineChart() {
    return this.renderLineChart({
      datasetLabels: ['Average clicks (all)'],
      dataExtractors: [
        newsletterData =>
          newsletterData != null
            ? newsletterData.clickCount / newsletterData.sentCount
            : null,
      ],
      datasetColors: chartColors,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  renderAverageOpenedClickCountLineChart() {
    return this.renderLineChart({
      datasetLabels: ['Average clicks (opened)'],
      dataExtractors: [
        newsletterData =>
          newsletterData != null
            ? newsletterData.clickCount / newsletterData.openCount
            : null,
      ],
      datasetColors: chartColors,
    });
  }

  render() {
    const { globalNewsletterStatisticsData } = this.props;
    const { refetching } = this.state;

    if (globalNewsletterStatisticsData.loading || refetching) {
      return <div className={s.lineChartContainer}>Loading...</div>;
    }

    return (
      <div>
        <div className={s.refreshButtonWrapper}>
          <button
            className={`btn btn-secondary ${s.refreshButton}`}
            onClick={async () => {
              this.setState({ refetching: true });
              await globalNewsletterStatisticsData.refetch(
                {
                  recommendationTypeKey: this.props.recommendationTypeKey,
                  noCache: true,
                },
                {
                  fetchPolicy: 'network-only',
                },
              );
              this.setState({ refetching: false });
            }}
          >
            <FaRefresh />
          </button>
        </div>
        <div className={s.lineChartContainer}>
          {this.renderAvgAbsolutePositionMetricLineChart('INL')}
        </div>
        <div className={s.halflineChartContainer}>
          {this.renderGroupClickRateLineChart()}
        </div>
        <div className={s.halflineChartContainer}>
          {this.renderGroupOpenRateLineChart()}
        </div>
        <div className={s.halflineChartContainer}>
          {this.renderSentAndOpenedLineChart()}
        </div>
        <div className={s.halflineChartContainer}>
          {this.renderOpenRateLineChart()}
        </div>
        <div className={s.lineChartContainer}>
          {this.renderClickCountLineChart()}
        </div>
        <div className={s.halflineChartContainer}>
          {this.renderAverageSentClickCountLineChart()}
        </div>
        <div className={s.halflineChartContainer}>
          {this.renderAverageOpenedClickCountLineChart()}
        </div>
      </div>
    );
  }
}

export default compose(
  graphql(getGlobalNewsletterStatistics, {
    name: 'globalNewsletterStatisticsData',
    options: props => ({
      variables: {
        recommendationTypeKey: props.recommendationTypeKey,
        noCache: false,
      },
    }),
  }),
  withStyles(s),
)(NewslettersChart);
