import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { compose } from 'react-apollo';
import { withCookies } from 'react-cookie';

import s from './NewsletterArticlesPage.scss';
import withAuthentication from '../Auth/AuthenticationWrapper';
import PaginatedList from '../PaginatedList';
import CollapsiblePanel from '../CollapsiblePanel';
import { getNewsletterArticlesQuery } from '../../data/queries/newsletterQueries';
import {
  RECOMMENDATION_TYPE_COOKIE,
  DEFAULT_RECOMMENDATION_TYPE_KEY,
} from '../../constants';

class NewsletterArticlesPage extends React.Component {
  static propTypes = {
    cookies: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    match: PropTypes.shape({
      params: PropTypes.shape({
        newsletterId: PropTypes.string.isRequired,
      }),
    }).isRequired,
  };

  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      recommendationTypeKey:
        props.cookies.get(RECOMMENDATION_TYPE_COOKIE) ||
        DEFAULT_RECOMMENDATION_TYPE_KEY,
      loading: false,
      newsletterArticles: [],
      newsletterArticleCount: 0,
    };

    this.fetchNewsletterArticles = this.fetchNewsletterArticles.bind(this);
    this.renderNewsletterArticles = this.renderNewsletterArticles.bind(this);
  }

  componentDidUpdate() {
    const recommendationTypeKey =
      this.props.cookies.get(RECOMMENDATION_TYPE_COOKIE) ||
      DEFAULT_RECOMMENDATION_TYPE_KEY;
    if (this.state.recommendationTypeKey !== recommendationTypeKey) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ recommendationTypeKey });
    }
  }

  async fetchNewsletterArticles({ offset, limit }) {
    const { recommendationTypeKey } = this.state;

    this.setState({
      loading: true,
    });

    const result = await this.context.client.query({
      query: getNewsletterArticlesQuery,
      variables: {
        recommendationTypeKey,
        newsletterId: this.props.match.params.newsletterId,
        offset,
        limit,
      },
    });

    if (result.data && result.data.getNewsletterArticles) {
      this.setState({
        loading: false,
        newsletterArticles: result.data.getNewsletterArticles.articles,
        newsletterArticleCount: result.data.getNewsletterArticles.count,
      });
    } else {
      this.setState({
        loading: false,
        newsletterArticles: [],
        newsletterArticleCount: 0,
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  renderNewsletterArticles(newsletterArticle) {
    return (
      <CollapsiblePanel
        key={newsletterArticle.externalArticleId}
        headerContent={
          <div className={s.articleHeader}>
            <div className={s.title}>
              Article:{' '}
              <a
                href={newsletterArticle.articleLink}
                target="_blank"
                rel="noreferrer"
              >
                {newsletterArticle.articleTitle ||
                  newsletterArticle.externalArticleId}
              </a>
            </div>
            <div>
              <span className={s.clicksColumn}>
                Clicks: {newsletterArticle.clickCount}
              </span>
            </div>
          </div>
        }
        bodyContent={
          <div>
            <div>External id: {newsletterArticle.externalArticleId}</div>
          </div>
        }
      />
    );
  }

  render() {
    const {
      recommendationTypeKey,
      loading,
      newsletterArticles,
      newsletterArticleCount,
    } = this.state;

    return (
      <div className={s.newsletterArticlesPage}>
        <PaginatedList
          recommendationTypeKey={recommendationTypeKey}
          items={newsletterArticles}
          itemCount={newsletterArticleCount}
          renderItem={this.renderNewsletterArticles}
          fetchItems={this.fetchNewsletterArticles}
          loading={loading}
        />
      </div>
    );
  }
}

export default compose(
  withAuthentication,
  withCookies,
  withStyles(s),
)(NewsletterArticlesPage);
