import Cookies from 'js-cookie';

import { USER_TOKEN_ID } from '../../constants';

export const setUserTokenCookie = token => {
  Cookies.set(USER_TOKEN_ID, token, { expires: 7 });
};

export const getUserTokenCookie = () => Cookies.get(USER_TOKEN_ID);

export const removeUserTokenCookie = () => Cookies.remove(USER_TOKEN_ID);
