import React from 'react';

import NewsletterUserNewsletterDataPage from 'components/NewsletterUserNewsletterDataPage';

class NewsletterUserNewsletterDataPageWrapper extends React.Component {
  render() {
    return <NewsletterUserNewsletterDataPage />;
  }
}

export default NewsletterUserNewsletterDataPageWrapper;
