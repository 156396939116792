import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { compose } from 'react-apollo';
import { Link } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import moment from 'moment';

import s from './NewsletterUsersPage.scss';
import withAuthentication from '../Auth/AuthenticationWrapper';
import PaginatedList from '../PaginatedList';
import CollapsiblePanel from '../CollapsiblePanel';
import { getNewsletterUsersQuery } from '../../data/queries/newsletterQueries';
import NewsletterUserMetricChart from '../NewsletterUserMetricChart';
import { roundNumber } from '../../util/math';
import {
  RECOMMENDATION_TYPE_COOKIE,
  DEMO_RECOMMENDATION_TYPE_KEY,
  DEFAULT_RECOMMENDATION_TYPE_KEY,
  DEMO_METRIC_KEYS,
  LIVE_METRIC_KEYS,
  METRIC_LABELS,
} from '../../constants';

class NewsletterUsersPage extends React.Component {
  static propTypes = {
    cookies: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      recommendationTypeKey:
        props.cookies.get(RECOMMENDATION_TYPE_COOKIE) ||
        DEFAULT_RECOMMENDATION_TYPE_KEY,
      loading: false,
      users: [],
      userCount: 0,
    };

    this.fetchUsers = this.fetchUsers.bind(this);
    this.renderUser = this.renderUser.bind(this);
  }

  componentDidUpdate() {
    const recommendationTypeKey =
      this.props.cookies.get(RECOMMENDATION_TYPE_COOKIE) ||
      DEFAULT_RECOMMENDATION_TYPE_KEY;
    if (this.state.recommendationTypeKey !== recommendationTypeKey) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ recommendationTypeKey });
    }
  }

  async fetchUsers({ offset, limit }) {
    const { recommendationTypeKey } = this.state;

    this.setState({
      loading: true,
    });

    const result = await this.context.client.query({
      query: getNewsletterUsersQuery,
      variables: {
        recommendationTypeKey,
        offset,
        limit,
      },
    });

    if (result.data && result.data.getNewsletterUsers) {
      this.setState({
        loading: false,
        users: result.data.getNewsletterUsers.users,
        userCount: result.data.getNewsletterUsers.count,
      });
    } else {
      this.setState({
        loading: false,
        users: [],
        userCount: 0,
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  renderUser(user) {
    const { recommendationTypeKey } = this.state;
    const dateFormat = 'DD.MM.YYYY';
    const firstInteractionDate = moment(
      parseInt(user.firstInteraction, 10),
    ).format(dateFormat);
    const lastNewsletterReceivedDate = moment(
      parseInt(user.lastNewsletterReceived, 10),
    ).format(dateFormat);

    return (
      <CollapsiblePanel
        key={user.externalUserId}
        headerContent={
          <div className={s.userHeader}>
            <div className={s.userDataContainer}>
              <div>
                <span>
                  User:{' '}
                  <Link to={`/users/${user.externalUserId}`}>
                    {user.externalUserId}
                  </Link>
                </span>
              </div>
              <div>
                <span className={s.infoSpan}>Interaction Dates:</span>
                <span className={s.infoSpan}>
                  First Interaction: {firstInteractionDate}
                </span>
                <span className={s.infoSpan}>
                  Last Newsletter Received: {lastNewsletterReceivedDate}
                </span>
              </div>
              <div>
                <span className={s.infoSpan}>Newsletters:</span>
                <span className={s.infoSpan}>
                  Received: {user.newsletterCount}
                </span>
                <span className={s.infoSpan}>Opened: {user.openCount}</span>
                <span className={s.infoSpan}>
                  Open rate:{' '}
                  {roundNumber({
                    value: (user.openCount / user.newsletterCount) * 100,
                    numberOfCommaDigits: 2,
                  })}
                  %
                </span>
              </div>
              <div>
                <span className={s.infoSpan}>
                  Newsletter clicks: {user.newsletterInteractionCount}
                </span>
                <span className={s.infoSpan}>
                  Average clicks (all):{' '}
                  {roundNumber({
                    value:
                      user.newsletterInteractionCount / user.newsletterCount,
                    numberOfCommaDigits: 2,
                  })}
                </span>
                <span className={s.infoSpan}>
                  Average clicks (opened):{' '}
                  {roundNumber({
                    value: user.newsletterInteractionCount / user.openCount,
                    numberOfCommaDigits: 2,
                  })}
                </span>
                <span className={s.infoSpan}>
                  Webpage clicks: {user.webpageInteractionCount}
                </span>
              </div>
              <div>
                <span className={s.infoSpan}>Average metrics:</span>
                {user.averageClickMetrics != null &&
                  (recommendationTypeKey === DEMO_RECOMMENDATION_TYPE_KEY
                    ? DEMO_METRIC_KEYS
                    : LIVE_METRIC_KEYS
                  ).map(metricKey => (
                    <span className={s.infoSpan}>
                      {METRIC_LABELS[metricKey] || metricKey}:{' '}
                      {roundNumber({
                        value: user.averageClickMetrics[metricKey],
                        numberOfCommaDigits: 4,
                      })}
                    </span>
                  ))}
              </div>
            </div>
            <div className={s.lineChartContainer}>
              <NewsletterUserMetricChart
                recommendationTypeKey={recommendationTypeKey}
                externalUserId={user.externalUserId}
                compact
              />
            </div>
          </div>
        }
      />
    );
  }

  render() {
    const { recommendationTypeKey, loading, users, userCount } = this.state;

    return (
      <div className={s.newsletterUsersPage}>
        <PaginatedList
          recommendationTypeKey={recommendationTypeKey}
          items={users}
          itemCount={userCount}
          renderItem={this.renderUser}
          fetchItems={this.fetchUsers}
          loading={loading}
        />
      </div>
    );
  }
}

export default compose(
  withAuthentication,
  withCookies,
  withStyles(s),
)(NewsletterUsersPage);
