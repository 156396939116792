exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2nhzB{width:100%;height:150px;background-color:#2e4b5f;color:#fff;padding:30px 100px}@media (max-width:992px){._2nhzB{padding:30px 50px}}@media (max-width:768px){._2nhzB{padding:30px 15px}}._2nhzB ._35uox{height:100%;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center}._2nhzB ._35uox a{color:#fff;text-decoration:none}._2nhzB ._35uox a:hover{background:#d8f2fe;-webkit-background-clip:text;background-clip:text;-webkit-text-fill-color:transparent}._2nhzB ._35uox ._12yR_{padding:5px 10px}@media (max-width:1200px){._2nhzB ._35uox ._12yR_{padding:4px 10px}}@media (max-width:992px){._2nhzB ._35uox ._12yR_{padding:3px 10px}}@media (max-width:768px){._2nhzB ._35uox ._12yR_{padding:2px 10px}}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"footer": "_2nhzB",
	"footerItems": "_35uox",
	"footerItemWrapper": "_12yR_"
};