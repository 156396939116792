import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Link } from 'react-router-dom';

import s from './Footer.scss';

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.renderLink = this.renderLink.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  renderLink(url, message) {
    return (
      <Link to={url}>
        <div className={s.footerItemWrapper}>
          <span>{message}</span>
        </div>
      </Link>
    );
  }

  render() {
    return (
      <div className={s.footer}>
        <div className={s.footerItems}>
          <a href="https://danube.ai/" target="_blank" rel="noreferrer">
            powered by danube.ai
          </a>
        </div>
      </div>
    );
  }
}

export default withStyles(s)(Footer);
