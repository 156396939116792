import { defineMessages } from 'react-intl';

const messages = defineMessages({
  logout: {
    id: 'topNav.logout',
    defaultMessage: 'Logout',
    description: 'label for top nav',
  },
  login: {
    id: 'topNav.login',
    defaultMessage: 'Login',
    description: 'label for top nav',
  },
  users: {
    id: 'topNav.users',
    defaultMessage: 'Users',
    description: 'label for top nav',
  },
  newsletters: {
    id: 'topNav.newsletters',
    defaultMessage: 'Newsletters',
    description: 'label for top nav',
  },
});

export default messages;
