import React from 'react';
import PropTypes from 'prop-types';
import { Navbar, Nav, NavDropdown, NavItem } from 'react-bootstrap';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { graphql, compose } from 'react-apollo';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import FaUser from 'react-icons/lib/fa/user';
import { FormattedMessage } from 'react-intl';

import s from './TopNav.scss';
import withLogout from '../Login/withLogout';
import { meQuery } from '../../data/queries/userQueries';
import LanguageSwitcher from '../LanguageSwitcher';
import messages from './messages';
import { isServerSide } from '../../util';
import RecommendationTypeSelect from './RecommendationTypeSelect';

const MenuItem = NavDropdown.Item;

class TopNav extends React.Component {
  static propTypes = {
    meData: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      me: PropTypes.shape({
        id: PropTypes.string.isRequired,
        username: PropTypes.string.isRequired,
      }),
    }).isRequired,
    logout: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.handleUserDropdownSelect = this.handleUserDropdownSelect.bind(this);
  }

  handleUserDropdownSelect(e) {
    if (e === 'logout') {
      this.props.logout();
    } else if (e === 'login') {
      this.props.history.push('/login');
    }
  }

  render() {
    const {
      meData,
      location: { pathname },
    } = this.props;

    let userName = null;
    const userDropdownItems = [];

    if (isServerSide() || meData.loading) {
      // do nothing
    } else if (!meData.loading && meData.me) {
      userName = meData.me.username;
      userDropdownItems.push(
        <MenuItem key="logout" eventKey="logout">
          <FormattedMessage {...messages.logout} />
        </MenuItem>,
      );
    } else {
      // do nothing
    }

    return (
      <Navbar collapseOnSelect expand="lg" className={s.topNavContainer}>
        <Navbar.Brand className={s.logoContainer}>
          <Link className={s.logoLink} to="/">
            <div className={s.logoNameFull}>danube.ai</div>
            <div className={s.logoNameInitials}>danube.ai</div>
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle
          className={s.navbarToggle}
          aria-controls="responsive-navbar-nav"
        />
        <Navbar.Collapse
          className={s.navbarCollapse}
          id="responsive-navbar-nav"
        >
          <Nav className={`mr-auto ${s.nav}`}>
            <NavItem key="users" className={s.navItem}>
              {meData.me ? (
                <Link
                  to="/users"
                  className={`${s.navLink} ${
                    pathname === '/users' ? s.navLinkActive : ''
                  }`}
                >
                  <FormattedMessage {...messages.users} />
                </Link>
              ) : null}
              {meData.me ? (
                <Link
                  to="/newsletters"
                  className={`${s.navLink} ${
                    pathname === '/newsletters' ? s.navLinkActive : ''
                  }`}
                >
                  <FormattedMessage {...messages.newsletters} />
                </Link>
              ) : null}
            </NavItem>
          </Nav>
          <Nav className={`ml-auto ${s.nav}`}>
            {pathname !== '/login' && <RecommendationTypeSelect />}
            {userName != null && (
              <Nav onSelect={this.handleUserDropdownSelect}>
                <NavDropdown
                  className={s.navDropdown}
                  title={
                    <span>
                      <FaUser /> {userName}
                    </span>
                  }
                  alignRight
                >
                  {userDropdownItems}
                </NavDropdown>
              </Nav>
            )}
            <LanguageSwitcher dropDownClassName={s.languageDropdown} />
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

TopNav.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default compose(
  graphql(meQuery, {
    name: 'meData',
  }),
  withRouter,
  withLogout,
  withStyles(s),
)(TopNav);
