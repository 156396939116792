exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2g8Bi{display:-ms-flexbox;display:flex;width:100%;-ms-flex-pack:left;justify-content:left}._2g8Bi ._1YuEm{width:100%;margin-top:5px;margin-bottom:5px}._2g8Bi ._1YuEm ._36ZKV{border:1px solid #fff;border-radius:0;background:none}._2g8Bi ._1YuEm ._36ZKV ._3fAGa{padding:0;border:none}._2g8Bi ._1YuEm ._36ZKV ._3fAGa ._3wYQ8{padding:10px 0;height:100%;width:100%;border:none;border-radius:0;background:transparent;font-weight:700;color:#fff}._2g8Bi ._1YuEm ._36ZKV ._3fAGa ._3wYQ8 ._1FVJn{width:100%;display:-ms-flexbox;display:flex;-ms-flex-pack:start;justify-content:flex-start;-ms-flex-align:start;align-items:flex-start;white-space:normal;font-size:17px}._2g8Bi ._1YuEm ._36ZKV ._3fAGa ._3wYQ8 ._1FVJn ._1T_bt{font-size:30px;margin:0 20px}._2g8Bi ._1YuEm ._36ZKV ._3fAGa ._3wYQ8 ._1FVJn ._1TMC3{max-width:calc(100% - 80px);-ms-flex:1 1;flex:1 1}._2g8Bi ._1YuEm ._36ZKV ._2PJ8s ._36Gys{padding:10px 0 10px 70px;white-space:normal;font-size:17px;color:#fff}._2g8Bi ._1YuEm ._36ZKV ._2PJ8s:not([class*=show]){display:block;height:0;overflow:hidden}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"wrappingDiv": "_2g8Bi",
	"accordion": "_1YuEm",
	"accordionCard": "_36ZKV",
	"accordionCardHeader": "_3fAGa",
	"accordionCardToggle": "_3wYQ8",
	"userInfowrappingDiv": "_1FVJn",
	"icon": "_1T_bt",
	"headerContent": "_1TMC3",
	"accordionCollapse": "_2PJ8s",
	"accordionCardBody": "_36Gys"
};