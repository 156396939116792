import React from 'react';

import NewsletterUsersPage from 'components/NewsletterUsersPage';

class NewsletterUsersPageWrapper extends React.Component {
  render() {
    return <NewsletterUsersPage />;
  }
}

export default NewsletterUsersPageWrapper;
