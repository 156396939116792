/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { withRouter } from 'react-router';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FormattedMessage } from 'react-intl';
import { compose } from 'react-apollo';

import withLogin from './withLogin';
import s from './Login.scss';
import Loading from '../Loading';
import messages from './messages';

class Login extends React.Component {
  static propTypes = {
    loggedMode: PropTypes.string.isRequired,
    login: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  static renderErrorMessage(msg) {
    return <div className={s.formError}>{msg}</div>;
  }

  constructor(props) {
    super(props);

    this.state = {
      loginErrors: [],
      loading: false,
    };

    this.handleLogin = this.handleLogin.bind(this);
  }

  async handleLogin(values, { setSubmitting }) {
    const { login } = this.props;
    setSubmitting(true);

    this.setState({ loading: true });

    const result = await login({
      username: values.username,
      password: values.password,
    });

    if (!result) {
      this.setState({
        loginErrors: ['Invalid credentials.'],
        loading: false,
      });
      setSubmitting(false);
    } else {
      window.location = '/';
    }
  }

  render() {
    const { loginErrors, loading } = this.state;

    return (
      <div className={s.container}>
        <Formik
          initialValues={{ username: '', password: '' }}
          validate={values => {
            const errors = {};
            if (!values.username) {
              errors.username = 'Required';
            }
            if (!values.password) {
              errors.password = 'Required';
            }
            return errors;
          }}
          onSubmit={this.handleLogin}
        >
          {({ isSubmitting }) => (
            <Form className={s.form}>
              <div className={s.formContent}>
                <fieldset>
                  <div className={s.formGroup}>
                    <label htmlFor="username">
                      <FormattedMessage {...messages.user} />
                    </label>
                    <Field type="text" id="username" name="username" />
                    <ErrorMessage
                      name="username"
                      render={Login.renderErrorMessage}
                    />
                  </div>
                  <div className={s.formGroup}>
                    <label htmlFor="password">
                      <FormattedMessage {...messages.password} />
                    </label>
                    <Field type="password" id="password" name="password" />
                    <ErrorMessage
                      name="password"
                      render={Login.renderErrorMessage}
                    />
                  </div>
                </fieldset>
                {loginErrors.length > 0 && (
                  <div>
                    {loginErrors.map(error => (
                      <div key={error} className={s.formError}>
                        {error}
                      </div>
                    ))}
                  </div>
                )}
                <button
                  type="submit"
                  className="btn btn-ur btn-round btn-wide"
                  disabled={isSubmitting}
                >
                  <FormattedMessage {...messages.login} />
                </button>
              </div>
            </Form>
          )}
        </Formik>
        {loading && <Loading />}
      </div>
    );
  }
}

export default compose(withLogin, withRouter, withStyles(s))(Login);
