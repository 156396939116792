exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3BAXR{width:100%;max-width:1500px;margin:auto;padding:0 15px}._3BAXR ._27s2U{display:inline-block;margin-right:25px}._3BAXR ._1jrJ8{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:start;justify-content:flex-start;-ms-flex-align:start;align-items:flex-start}._3BAXR ._1jrJ8 ._3KFE4{text-align:left;-ms-flex:1 1;flex:1 1}._3BAXR ._1jrJ8 ._2stGi{width:230px;min-width:230px;height:100px;margin-bottom:-20px;overflow:hidden}._3BAXR ._1jrJ8 ._2stGi canvas{height:100%}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"newsletterUsersPage": "_3BAXR",
	"infoSpan": "_27s2U",
	"userHeader": "_1jrJ8",
	"userDataContainer": "_3KFE4",
	"lineChartContainer": "_2stGi"
};