/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const getNewsletterUsersQuery = gql`
  query getNewsletterUsers(
    $recommendationTypeKey: String!
    $offset: Int
    $limit: Int
  ) {
    getNewsletterUsers(
      recommendationTypeKey: $recommendationTypeKey
      offset: $offset
      limit: $limit
    ) {
      count
      users {
        externalUserId
        newsletterCount
        openCount
        webpageInteractionCount
        newsletterInteractionCount
        firstInteraction
        lastNewsletterReceived
        averageClickMetrics {
          absolutePositionMetric
          relativePositionMetric
          oldMetric
          positionDivisorMetric
        }
      }
    }
  }
`;

export const getNewsletterUserNewslettersQuery = gql`
  query getNewsletterUserNewsletters(
    $recommendationTypeKey: String!
    $externalUserId: String!
    $offset: Int
    $limit: Int
  ) {
    getNewsletterUserNewsletters(
      recommendationTypeKey: $recommendationTypeKey
      externalUserId: $externalUserId
      offset: $offset
      limit: $limit
    ) {
      count
      userNewsletters {
        newsletterId
        externalNewsletterId
        newsletterSubject
        articleCount
        clickCount
        averageClickMetrics {
          absolutePositionMetric
          relativePositionMetric
          oldMetric
          positionDivisorMetric
        }
        receivedAt
      }
    }
  }
`;

export const getNewsletterUserNewsletterDataQuery = gql`
  query getNewsletterUserNewsletterData(
    $recommendationTypeKey: String!
    $externalUserId: String!
    $newsletterId: String!
  ) {
    getNewsletterUserNewsletterData(
      recommendationTypeKey: $recommendationTypeKey
      externalUserId: $externalUserId
      newsletterId: $newsletterId
    ) {
      orderedArticles {
        id
        externalArticleId
        danubeScore
      }
      originalOrder
      articleRecommendationItems {
        id
        externalApiId
        externalApiLink
        title
      }
      userInteractions {
        articleId
        type
        position
        metric {
          absolutePositionMetric
          relativePositionMetric
          oldMetric
          positionDivisorMetric
        }
      }
      averageMetrics {
        absolutePositionMetric
        relativePositionMetric
        oldMetric
        positionDivisorMetric
      }
    }
  }
`;

export const getGlobalUserNewsletterMetricsQuery = gql`
  query getGlobalUserNewsletterMetrics(
    $recommendationTypeKey: String!
    $externalUserId: String!
  ) {
    getGlobalUserNewsletterMetrics(
      recommendationTypeKey: $recommendationTypeKey
      externalUserId: $externalUserId
    ) {
      absolutePositionMetric
      relativePositionMetric
      oldMetric
      positionDivisorMetric
    }
  }
`;

export const getNewslettersQuery = gql`
  query getNewsletters(
    $recommendationTypeKey: String!
    $offset: Int
    $limit: Int
  ) {
    getNewsletters(
      recommendationTypeKey: $recommendationTypeKey
      offset: $offset
      limit: $limit
    ) {
      count
      newsletters {
        newsletterId
        externalNewsletterId
        newsletterSubject
        sentAt
        sentCount
        openCount
        clickCount
        createdAt
      }
    }
  }
`;

export const getNewsletterArticlesQuery = gql`
  query getNewsletterArticles(
    $recommendationTypeKey: String!
    $newsletterId: String!
    $offset: Int
    $limit: Int
  ) {
    getNewsletterArticles(
      recommendationTypeKey: $recommendationTypeKey
      newsletterId: $newsletterId
      offset: $offset
      limit: $limit
    ) {
      count
      articles {
        articleId
        externalArticleId
        articleTitle
        articleLink
        clickCount
      }
    }
  }
`;

export const getGlobalNewsletterStatistics = gql`
  query getGlobalNewsletterStatistics(
    $recommendationTypeKey: String!
    $noCache: Boolean
  ) {
    getGlobalNewsletterStatistics(
      recommendationTypeKey: $recommendationTypeKey
      noCache: $noCache
    ) {
      newsletterId
      externalNewsletterId
      newsletterSubject
      sentAt
      sentCount
      openCount
      clickCount
      groupMetric {
        userGroupName
        newsletterGroupName
        avgAbsolutePositionMetric
        clickSum
        openingSum
        newsletterGroupSize
        userGroupSize
      }
    }
  }
`;
