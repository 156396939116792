exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3iY75{width:100%;max-width:1500px;margin:auto;padding:30px 0 100px}._3iY75 h4{color:#fff}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"landingPageContainer": "_3iY75"
};