exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2bR1y{width:100%;max-width:1500px;margin:auto;padding:0 15px}._2bR1y .jdfpj{display:inline-block;margin-right:25px}._2bR1y .l4qhC{width:100%;text-align:left;overflow:hidden}._2bR1y .l4qhC .qSyE6{-o-text-overflow:ellipsis;text-overflow:ellipsis;overflow:hidden;white-space:nowrap}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"newslettersPage": "_2bR1y",
	"infoSpan": "jdfpj",
	"newsletterHeader": "l4qhC",
	"subject": "qSyE6"
};