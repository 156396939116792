import React from 'react';

import NewsletterArticlesPage from 'components/NewsletterArticlesPage';

class NewsletterArticlesPageWrapper extends React.Component {
  render() {
    return <NewsletterArticlesPage />;
  }
}

export default NewsletterArticlesPageWrapper;
