import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FormattedMessage } from 'react-intl';
import FaCaretLeft from 'react-icons/lib/fa/angle-left';
import FaCaretRight from 'react-icons/lib/fa/angle-right';
import FaCaretRightDouble from 'react-icons/lib/fa/angle-double-right';
import FaCaretLeftDouble from 'react-icons/lib/fa/angle-double-left';

import Loading from '../Loading';
import s from './PaginatedList.scss';
import messages from './messages';
import { NUMBER_OF_LIST_ITEMS } from '../../constants';

class PaginatedList extends React.Component {
  static propTypes = {
    recommendationTypeKey: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.any),
    itemCount: PropTypes.number,
    renderItem: PropTypes.func.isRequired,
    fetchItems: PropTypes.func.isRequired,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    recommendationTypeKey: null,
    items: [],
    itemCount: 0,
    loading: false,
  };

  constructor(props) {
    super(props);

    this.offset = 0;

    this.prevPage = this.prevPage.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.renderNavButtons = this.renderNavButtons.bind(this);
  }

  componentDidMount() {
    const { fetchItems } = this.props;

    // initial data fetch
    fetchItems({ offset: this.offset, limit: NUMBER_OF_LIST_ITEMS });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.recommendationTypeKey !== this.props.recommendationTypeKey) {
      // refetch data
      this.offset = 0;
      this.props.fetchItems({
        offset: this.offset,
        limit: NUMBER_OF_LIST_ITEMS,
      });
    }
  }

  prevPage(jumpTenPages) {
    const { fetchItems } = this.props;

    const offsetIncrement = jumpTenPages
      ? NUMBER_OF_LIST_ITEMS * 10
      : NUMBER_OF_LIST_ITEMS;

    const minOffset = 0;
    const newOffset = Math.max(minOffset, this.offset - offsetIncrement);
    this.offset = newOffset;

    fetchItems({ offset: this.offset, limit: NUMBER_OF_LIST_ITEMS });
  }

  nextPage(jumpTenPages) {
    const { itemCount, fetchItems } = this.props;

    const offsetIncrement = jumpTenPages
      ? NUMBER_OF_LIST_ITEMS * 10
      : NUMBER_OF_LIST_ITEMS;

    const maxOffset =
      Math.floor(Math.max(0, itemCount - 1) / NUMBER_OF_LIST_ITEMS) *
      NUMBER_OF_LIST_ITEMS;
    const newOffset = Math.min(maxOffset, this.offset + offsetIncrement);
    this.offset = newOffset;

    fetchItems({ offset: this.offset, limit: NUMBER_OF_LIST_ITEMS });
  }

  renderNavButtons() {
    const { itemCount } = this.props;

    const numberOfPages =
      Math.floor(Math.max(0, itemCount - 1) / NUMBER_OF_LIST_ITEMS) + 1;

    const currentPage = Math.floor(this.offset / NUMBER_OF_LIST_ITEMS) + 1;

    const maxOffset =
      Math.floor(Math.max(0, itemCount - 1) / NUMBER_OF_LIST_ITEMS) *
      NUMBER_OF_LIST_ITEMS;

    return (
      <div className={s.pagesLabelContainer}>
        <button
          className={s.leftButton}
          disabled={this.offset === 0}
          onClick={() => this.prevPage(true)}
        >
          <FaCaretLeftDouble className={s.arrow} />
        </button>
        <button
          className={s.leftButton}
          disabled={this.offset === 0}
          onClick={() => this.prevPage(false)}
        >
          <FaCaretLeft className={s.arrow} />
        </button>
        <div className={s.pageCounter}>
          <FormattedMessage
            {...messages.page}
            values={{ currentPage, numberOfPages }}
          />
        </div>
        <button
          className={s.rightButton}
          disabled={this.offset === maxOffset}
          onClick={() => this.nextPage(false)}
        >
          <FaCaretRight className={s.arrow} />
        </button>
        <button
          className={s.rightButton}
          disabled={this.offset === maxOffset}
          onClick={() => this.nextPage(true)}
        >
          <FaCaretRightDouble className={s.arrow} />
        </button>
      </div>
    );
  }

  render() {
    const { items, renderItem, loading } = this.props;

    return (
      <div>
        {this.renderNavButtons()}
        {items.map(item => renderItem(item))}
        {this.renderNavButtons()}
        {loading && <Loading />}
      </div>
    );
  }
}

export default withStyles(s)(PaginatedList);
