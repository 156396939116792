import { defineMessages } from 'react-intl';

const messages = defineMessages({
  user: {
    id: 'login.user',
    defaultMessage: 'User',
    description: 'label for user message',
  },
  password: {
    id: 'login.password',
    defaultMessage: 'Password',
    description: 'label for password message',
  },
  login: {
    id: 'login.login',
    defaultMessage: 'Login',
    description: 'label for login message',
  },
});

export default messages;
