import Login from './login';
import LandingPage from './landingPage';
import NewsletterUsersPage from './newsletterUsersPage';
import NewsletterUserNewslettersPage from './newsletterUserNewslettersPage';
import NewsletterUserNewsletterDataPage from './newsletterUserNewsletterDataPage';
import NewslettersPage from './newslettersPage';
import NewslettersArticlesPage from './newslettersArticlesPage';

const routes = [
  {
    path: '/',
    component: LandingPage,
    exact: true,
  },
  {
    path: '/login',
    component: Login,
    exact: true,
  },
  {
    path: '/users',
    component: NewsletterUsersPage,
    exact: true,
  },
  {
    path: '/users/:externalUserId',
    component: NewsletterUserNewslettersPage,
    exact: true,
  },
  {
    path: '/users/:externalUserId/:newsletterId',
    component: NewsletterUserNewsletterDataPage,
    exact: true,
  },
  {
    path: '/newsletters',
    component: NewslettersPage,
    exact: true,
  },
  {
    path: '/newsletters/:newsletterId',
    component: NewslettersArticlesPage,
    exact: true,
  },
];

export default routes;
