import React from 'react';
import PropTypes from 'prop-types';
import { Accordion, Card as AccordionCard } from 'react-bootstrap';
import FaAngleRight from 'react-icons/lib/fa/angle-right';
import FaAngleDown from 'react-icons/lib/fa/angle-down';
// import Chart from 'chart.js';

import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { compose } from 'react-apollo';
import s from './CollapsiblePanel.scss';

class CollapsiblePanel extends React.Component {
  static propTypes = {
    headerContent: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
    bodyContent: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  };

  static defaultProps = {
    bodyContent: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
    };
  }

  render() {
    const { headerContent, bodyContent } = this.props;
    const { expanded } = this.state;
    const panelEventKey = 'panel';

    return (
      <div className={s.wrappingDiv}>
        <Accordion
          className={s.accordion}
          onSelect={eventKey =>
            this.setState({ expanded: eventKey === panelEventKey })
          }
        >
          <AccordionCard className={s.accordionCard}>
            <AccordionCard.Header className={s.accordionCardHeader}>
              <Accordion.Toggle
                eventKey={panelEventKey}
                className={s.accordionCardToggle}
              >
                <div className={s.userInfowrappingDiv}>
                  <span className={s.icon}>
                    {expanded ? <FaAngleDown /> : <FaAngleRight />}
                  </span>
                  <div className={s.headerContent}>{headerContent}</div>
                </div>
              </Accordion.Toggle>
            </AccordionCard.Header>
            <Accordion.Collapse
              eventKey={panelEventKey}
              className={s.accordionCollapse}
            >
              <AccordionCard.Body className={s.accordionCardBody}>
                {bodyContent}
              </AccordionCard.Body>
            </Accordion.Collapse>
          </AccordionCard>
        </Accordion>
      </div>
    );
  }
}

export default compose(withStyles(s))(CollapsiblePanel);
