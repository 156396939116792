exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3a29u{width:100%;padding:20px 0;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center}._3a29u ._2Qauw{font-size:15px;height:26px;color:#fff;display:table;padding:0 15px}._3a29u ._2Qauw span{display:table-cell;vertical-align:middle}._3a29u .BSFHs,._3a29u .SAztA{width:26px;height:26px;padding:0;border:none;background:none}._3a29u .BSFHs ._2ZBMj,._3a29u .SAztA ._2ZBMj{width:26px;height:26px;margin:auto;color:#fff}._3a29u .BSFHs:disabled ._2ZBMj,._3a29u .SAztA:disabled ._2ZBMj{cursor:not-allowed;color:#333}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"pagesLabelContainer": "_3a29u",
	"pageCounter": "_2Qauw",
	"leftButton": "SAztA",
	"rightButton": "BSFHs",
	"arrow": "_2ZBMj"
};