exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".SVQ4c{width:100%;max-width:1500px;margin:auto;padding:0 15px}.SVQ4c ._18cw2{display:inline-block;min-width:95px}.SVQ4c ._2M-Hd{width:100%;text-align:left;overflow:hidden}.SVQ4c ._2M-Hd ._13n0E{-o-text-overflow:ellipsis;text-overflow:ellipsis;overflow:hidden;white-space:nowrap}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"newsletterArticlesPage": "SVQ4c",
	"clicksColumn": "_18cw2",
	"articleHeader": "_2M-Hd",
	"title": "_13n0E"
};